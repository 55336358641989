/***********Imports*********/
  import { initializeApp } from 'firebase/app';
  import { getAnalytics } from 'firebase/analytics';
  import {getAuth, signInWithRedirect, getRedirectResult,onAuthStateChanged, OAuthProvider, signOut, getAdditionalUserInfo, reauthenticateWithRedirect} from 'firebase/auth';
  import {WebAuth} from 'auth0-js';
  import { getFunctions, httpsCallable, connectFunctionsEmulator} from 'firebase/functions';
  import { initializeAppCheck, ReCaptchaEnterpriseProvider,getToken } from "firebase/app-check";
  
  
  const webauth = new WebAuth({        //Auth0 Initizilation
    domain:'login.gpsresearchlibrary.com',
    clientID:'mnBGAcSa6NvkdgkVkIsnJo2c5F8ISFZm'
  });

  const firebaseConfig = {      //Firebase Configuration
    apiKey: "AIzaSyDZBAl-OtUnr8fC91HZ9tYvQn_m6KAthKI",
    authDomain: "gpsresearchlibrary.com",
    projectId: "gpswebsite-25ffa",
    storageBucket: "gpswebsite-25ffa.appspot.com",
    messagingSenderId: "374796765621",
    appId: "1:374796765621:web:5fa7c964ab7f0afdb9b1fc",
    measurementId: "G-C72JHHYBWK"
  };
  const app = initializeApp(firebaseConfig); //Initizalize Firebase
  const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider('6Lf2Pt8pAAAAADwn4NhtaznrKCfgkup6drPAGsv1'),
      isTokenAutoRefreshEnabled: true
    });
  
  const analytics = getAnalytics(app);  //Initizalize Firebase Analytics
  const auth= getAuth(app);     //Initizalize Firebase Auth
  const functions = getFunctions(app);
 //connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  export {functions, httpsCallable};
  const provider = new OAuthProvider("oidc.auth0"); //OIDC Connection Firebase to Auth0
  provider.addScope('nickName.read');
  /*****************Functions****************/
  export const signInFire= async ()=>{     //Function to login to Firebase using Auth0 OIDC connection
    await signInWithRedirect(auth, provider);
  }
  
  export const eachElement= (selector, fn)=>{  //Gets elements in a class
    for (let e of document.querySelectorAll(selector)) {
      fn(e);
    }
  };
  
  export const logout= async ()=>{     //function to log user out of both Auth0 and firebase
    const temp= auth.currentUser.accessToken;
    await signOut(auth).then(() => {
    // Sign-out successful.
    webauth.logout({
      id_token_hint: temp
    });
    document.cookie = '__session=;max-age=-50; SameSite=Strict; secure';
    }).catch((error) => {
      // An error happened.
    });
    //token=0;
  }
  export {auth, OAuthProvider, appCheck, getRedirectResult, reauthenticateWithRedirect, onAuthStateChanged};
  export default {auth, eachElement, signInFire};